import React, { useState, useCallback } from 'react'
import { Page, InlineGrid, SkeletonPage, SkeletonBodyText, Toast, Frame, BlockStack, Box, Banner } from '@shopify/polaris'
import { useParams, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ProductSelectionModal } from '../components/ProductSelectionModal'
import { useBomDetails } from '../hooks/useBomDetails'
import { MaterialsCard } from '../components/MaterialsCard'
import { BomCard } from '../components/BomCard'
import { StatuCard } from '../components/StatusCard'

export const BundleDetailsView = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [isSaving, setIsSaving] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const {
    bom,
    products,
    isLoading,
    error,
    handleSave,
    handleQuantityChange,
    handleOutputProductSave,
    handleRemoveOutputProduct,
    handleRemoveMaterial,
    handleMaterialModalSave,
    handleStatusChange,
    showStatusBanner,
    setShowSatusBanner
  } = useBomDetails(id, setIsSaving)
  const [showMaterialModal, setShowMaterialModal] = useState(false)
  const [showOutputProductModal, setShowOutputProductModal] = useState(false)
  const [outputProductSearchValue, setOutputProductSearchValue] = useState('')
  const [materialSearchValue, setMaterialSearchValue] = useState('')

  const handleAddMaterial = useCallback((value) => {
    if (value && !showMaterialModal) {
      setShowMaterialModal(true)
    }
  }, [showMaterialModal])

  const handleMaterialSearch = useCallback((value) => {
    setMaterialSearchValue(value)
    setShowMaterialModal(true)
  }, [])

  const handleMaterialModalClose = useCallback(() => {
    setShowMaterialModal(false)
    setMaterialSearchValue('')
  }, [])

  const handleOutputProductSearch = useCallback((value) => {
    setOutputProductSearchValue(value)
    setShowOutputProductModal(true)
  }, [])

  const handleOutputProductModalClose = useCallback(() => {
    setShowOutputProductModal(false)
    setOutputProductSearchValue('')
  }, [])

  const handleSaveWithToast = useCallback(async () => {
    setIsSaving(true)
    try {
      const newBomId = await handleSave()
      setShowToast(true)
      if (newBomId && id === 'new') {
        navigate(`/bundles/${newBomId}`)
      }
    } catch (error) {
      console.error('Error saving bundle:', error)
    } finally {
      // setIsSaving(false)
    }
  }, [handleSave, navigate, id])

  const toggleToast = useCallback(() => setShowToast((active) => !active), [])

  const handleBackClick = useCallback(() => {
    navigate('/bundles')
  }, [navigate])

  if (isLoading) {
    return (
      <SkeletonPage primaryAction>
        <InlineGrid columns={['1fr', '2fr']} gap="400">
          <SkeletonBodyText lines={3} />
          <SkeletonBodyText lines={5} />
        </InlineGrid>
      </SkeletonPage>
    )
  }

  if (error) {
    return <Page title={t('bundles.createBundle')}>{error}</Page>
  }

  // if (!bom) return null

  const pageTitle = bom?.output_product
    ? `${bom.output_product.product_name}${bom.output_product.product_variant_name ? ` - ${bom.output_product.product_variant_name}` : ''}`
    : t('bundles.createBundle')

  const toastMarkup = showToast
    ? (
    <Toast content={t('bundles.bundleSaved')} onDismiss={toggleToast} />
      )
    : null

  return (
    <Frame>
      <Page
        title={pageTitle}
        primaryAction={{ content: t('bundles.save'), onAction: handleSaveWithToast, loading: isSaving, disabled: isSaving }}
        backAction={{ content: t('bundles.back'), onAction: handleBackClick }}
      >
        {showStatusBanner && (
          <div>
            <Box paddingBlock='400'>
              <Banner
                title={t('banner.bom_status')}
                onDismiss={() => setShowSatusBanner(false)}
                tone='info'
              >
              </Banner>
            </Box>
          </div>
        )}
        <InlineGrid columns={['oneThird', 'twoThirds']} gap="400" alignItems="start">
          <BlockStack gap="200">
            <BomCard
              bom={bom}
              onSelectProduct={handleOutputProductSearch}
              onRemoveOutputProduct={handleRemoveOutputProduct}
            />
            <StatuCard onChange={handleStatusChange} initialValue={bom.status} />
          </BlockStack>
          <MaterialsCard
            materials={bom.materials || []}
            onSearchChange={handleAddMaterial}
            onQuantityChange={handleQuantityChange}
            onRemoveMaterial={handleRemoveMaterial}
            onSelectProduct={handleMaterialSearch}
            searchLabel={t('bundles.addMaterials')}
          />
        </InlineGrid>

        <ProductSelectionModal
          open={showMaterialModal}
          onClose={handleMaterialModalClose}
          onSave={handleMaterialModalSave}
          products={products}
          title={t('bundles.allProducts')}
          allowMultiple={true}
          initialSearchValue={materialSearchValue}
          initialSelectedProducts={bom.materials ? bom.materials : []}
        />

        <ProductSelectionModal
          open={showOutputProductModal}
          onClose={handleOutputProductModalClose}
          onSave={handleOutputProductSave}
          products={products}
          title={t('bundles.allProducts')}
          allowMultiple={false}
          initialSelectedProducts={bom.output_product ? [bom.output_product] : []}
          initialSearchValue={outputProductSearchValue}
        />

        {toastMarkup}
      </Page>
    </Frame>
  )
}
