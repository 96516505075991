import React, { useEffect, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { backendRequest } from '../utils/axiosInstance'
import { DataUpdateContext } from '../context/context'

export const ShopifySubcriptionReturn = ({ navigateUrl }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { appBridge, updateSubscriptionPlan } = useContext(DataUpdateContext)

  useEffect(() => {
    const fetchSubscriptionData = async () => {
      try {
        const params = new URLSearchParams(location.search)
        const subcriptionId = params.get('subscriptionId')
        if (!subcriptionId) {
          return
        }
        const response = await backendRequest({ method: 'PATCH', url: `/shopify/subscriptions/${subcriptionId}`, data: { status: 'active' }, appBridge })
        if (response.status === 200 && response.data.status === 'active') {
          updateSubscriptionPlan(response.data.plan)
          navigate(navigateUrl)
        } else {
          console.error('Failed to confirm subscription status')
        }
      } catch (error) {
        console.error('An error occurred while processing the subscription return', error)
      }
    }

    fetchSubscriptionData()
  }, [location.search, navigate, appBridge, updateSubscriptionPlan, navigateUrl])
  return (
    <div />
  )
}
