import React, { useContext } from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import ShopifyLayout from './shared/layouts/ShopifyLayout'
import ReplenishmentView from './shared/views/ReplenishmentView'
import './App.css'
import { DataUpdateContext, DataUpdateProvider } from './shared/context/context'
import ErrorPage from './shared/components/ErrorPage'
import { PlanSelectionView } from './apps/replen/views/PlanSelectionView'
import { WelcomeView } from './apps/replen/views/WelcomeView'
import { SettingView } from './apps/replen/views/SettingView'
import { ShopifySubcriptionReturn } from './shared/views/ShopifySubcriptionReturn'

function App () {
  return (
      <div className='App'>
        <DataUpdateProvider>
          <RouteManager />
        </DataUpdateProvider>
      </div>
  )
}

function RouteManager () {
  const { isLogin, landingUrl } = useContext(DataUpdateContext)

  if (isLogin === null) {
    return <div />
  }

  if (isLogin === false) {
    return <ErrorPage errorCode='401' />
  }

  return (
    <Router>
      <Routes>
        <Route path='/shopify/subscription/return' element={<ShopifySubcriptionReturn navigateUrl='/replenishment'/>} />
        <Route path='*' element={<Navigate to={landingUrl} replace />} />
        <Route path='/welcome' element={<ShopifyLayout><WelcomeView /></ShopifyLayout>} />
        <Route path='/plan-selection' element={<ShopifyLayout><PlanSelectionView /></ShopifyLayout>} />
        <Route path='/setting' element={<ShopifyLayout><SettingView /></ShopifyLayout>} />
        <Route path='/replenishment' element={<ShopifyLayout><ReplenishmentView /></ShopifyLayout>} />
        <Route path='/401' element={<ErrorPage errorCode='401' />} />
        <Route path='/404' element={<ErrorPage errorCode='404' />} />
        <Route path='/500' element={<ErrorPage errorCode='500' />} />
      </Routes>
    </Router>
  )
}

export default App
